import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heart, MessageCircle, Send } from 'lucide-react';
import OptimizedImage from '../components/OptimizedImage';
import { useAuth } from '../context/AuthContext';
import api from '../api/config';

// Separate Post component
const Post = ({ post: initialPost, currentUser, onPostUpdate }) => {
  const navigate = useNavigate();
  const [isLiked, setIsLiked] = useState(initialPost?.likes?.includes(currentUser?._id));
  const [showComments, setShowComments] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [post, setPost] = useState(initialPost);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const handleLike = async () => {
    try {
      const response = await api.post(`/api/posts/${post._id}/like`);
      setPost(response.data);
      setIsLiked(!isLiked);
      if (onPostUpdate) onPostUpdate();
    } catch (error) {
      console.error('Error liking post:', error);
    }
  };

  const handleComment = async (e) => {
    e.preventDefault();
    if (!newComment.trim()) return;

    try {
      const response = await api.post(`/api/posts/${post._id}/comment`, {
        content: newComment
      });
      setPost(response.data);
      setNewComment('');
      if (onPostUpdate) onPostUpdate();
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handlePostClick = (e) => {
    // Don't navigate if clicking on interactive elements
    const isInteractive = e.target.closest('button') || e.target.closest('form');
    if (!isInteractive) {
      navigate(`/posts/${post._id}`);
    }
  };

  return (
    <div 
      className="bg-white rounded-lg shadow-sm overflow-hidden mb-4 cursor-pointer"
      onClick={handlePostClick}>
      <div className="p-4 flex items-center gap-3">
        <div className="w-10 h-10 bg-[#56C5B9] rounded-full flex items-center justify-center">
          <span className="text-white font-semibold">
            {post.user?.username?.[0]?.toUpperCase()}
          </span>
        </div>
        <div>
          <h3 className="font-semibold">{post.user?.username}</h3>
          <p className="text-xs text-gray-500">
            {formatDate(post.createdAt)}
          </p>
        </div>
      </div>

      {post.image && (
        <OptimizedImage
          src={post.image}
          alt="Post content"
          className="w-full h-96 object-cover"
        />
      )}

      <div className="p-4">
        <p className="text-gray-700 mb-4">{post.content}</p>

        {Array.isArray(post.foods) && post.foods.length > 0 && (
          <div className="mb-4 flex flex-wrap gap-2">
            {post.foods.map(food => (
              <span key={food._id} className="px-2 py-1 bg-[#56C5B9]/10 text-[#56C5B9] rounded-full text-sm">
                {food.name}
              </span>
            ))}
          </div>
        )}

        <div className="flex items-center gap-6">
          <button
            onClick={handleLike}
            className="flex items-center gap-2 text-gray-500 hover:text-[#56C5B9]"
          >
            <Heart
              size={20}
              className={isLiked ? 'fill-[#56C5B9] text-[#56C5B9]' : ''}
            />
            <span className="text-sm">{post.likes?.length || 0}</span>
          </button>
          <button
            onClick={() => setShowComments(!showComments)}
            className="flex items-center gap-2 text-gray-500 hover:text-[#56C5B9]"
          >
            <MessageCircle size={20} />
            <span className="text-sm">{post.comments?.length || 0}</span>
          </button>
        </div>

        {showComments && (
          <div className="mt-4 pt-4 border-t">
            {Array.isArray(post.comments) && post.comments.map((comment, index) => (
              <div key={comment._id || index} className="flex gap-3 mb-3">
                <div className="w-8 h-8 bg-gray-100 rounded-full flex items-center justify-center">
                  <span className="text-sm font-medium">
                    {comment.user?.username?.[0]?.toUpperCase()}
                  </span>
                </div>
                <div className="flex-1">
                  <p className="text-sm">
                    <span className="font-medium">{comment.user?.username}</span>{' '}
                    {comment.content}
                  </p>
                  <p className="text-xs text-gray-500">
                    {formatDate(comment.createdAt)}
                  </p>
                </div>
              </div>
            ))}

            <form onSubmit={handleComment} className="flex gap-2 mt-4">
              <input
                type="text"
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                placeholder="Add a comment..."
                className="flex-1 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#56C5B9]"
              />
              <button
                type="submit"
                disabled={!newComment.trim()}
                className="p-2 text-[#56C5B9] hover:bg-[#56C5B9]/10 rounded-lg disabled:opacity-50"
              >
                <Send size={20} />
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default function FeedPage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const loader = useRef(null);

  const loadPosts = useCallback(async (pageNum = 1) => {
    try {
      console.log('Loading posts for page:', pageNum);
      setLoading(true);
      
      const response = await api.get('/api/posts', {
        params: {
          page: pageNum,
          limit: 10
        }
      });

      console.log('Posts response:', response.data);
      
      if (!response.data || !response.data.posts) {
        throw new Error('Invalid response format');
      }
      
      if (pageNum === 1) {
        setPosts(response.data.posts);
      } else {
        setPosts(prev => [...prev, ...response.data.posts]);
      }
      
      setHasMore(response.data.hasMore);
      setError('');
    } catch (error) {
      console.error('Error loading posts:', error);
      setError(error.response?.data?.message || 'Failed to load posts');
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  }, []);

  // Initial load
  useEffect(() => {
    console.log('Initial load effect running');
    loadPosts(1);
  }, [loadPosts]);

  // Load more when page changes
  useEffect(() => {
    if (page > 1) {
      console.log('Loading more posts for page:', page);
      loadPosts(page);
    }
  }, [page, loadPosts]);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting && hasMore && !loading && !error) {
      setPage(prev => prev + 1);
    }
  }, [hasMore, loading, error]);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0
    };
    
    const observer = new IntersectionObserver(handleObserver, option);
    const currentLoader = loader.current;
    
    if (currentLoader) {
      observer.observe(currentLoader);
    }
    
    return () => {
      if (currentLoader) {
        observer.unobserve(currentLoader);
      }
    };
  }, [handleObserver]);

  return (
    <div className="min-h-screen bg-gray-50 pb-32">
      <div className="bg-[#56C5B9] pt-8 pb-16 px-4">
        <div className="max-w-xl mx-auto">
          <h1 className="text-2xl font-bold text-white mb-2">Feed</h1>
          <p className="text-white/80">See what the community is sharing</p>
        </div>
      </div>

      <div className="max-w-xl mx-auto px-4 -mt-8">
        {error && (
          <div className="bg-red-100 text-red-700 p-4 rounded-lg mb-4 flex items-center justify-between">
            <span>{error}</span>
            <button 
              onClick={() => { 
                setError('');
                setPage(1);
                loadPosts(1);
              }}
              className="text-red-700 hover:text-red-900"
            >
              Try Again
            </button>
          </div>
        )}

        <div className="space-y-4">
          {loading && posts.length === 0 ? (
            <div className="flex justify-center py-12">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#56C5B9]" />
            </div>
          ) : (
            <>
              {posts.map(post => post && (
                <Post 
                  key={post._id} 
                  post={post} 
                  currentUser={user}
                  onPostUpdate={() => loadPosts(page)}
                />
              ))}
              
              <div ref={loader} className="py-4">
                {loading && posts.length > 0 && (
                  <div className="flex justify-center">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#56C5B9]" />
                  </div>
                )}
              </div>

              {!loading && posts.length === 0 && (
                <div className="bg-white rounded-lg p-8 text-center">
                  <p className="text-gray-500">No posts yet. Be the first to share!</p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}