import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LogOut, Settings, Edit, ChevronRight, Info, ShieldCheck } from 'lucide-react';
import { getUserPreferences } from '../api/preferences';
import { useAuth } from '../context/AuthContext';
import api from '../api/config';

const BMISection = ({ profile }) => {
  const [showInfo, setShowInfo] = useState(false);
  
  return (
    <div className="bg-white rounded-lg shadow-sm p-4 mb-4">
      <div className="flex items-center gap-2 mb-3 relative">
        <h2 className="text-lg font-semibold">Physical Stats</h2>
        <button
          onClick={() => setShowInfo(!showInfo)}
          className="hover:bg-gray-100 p-1 rounded-full"
        >
          <Info size={16} className="text-gray-400" />
        </button>
        
        {showInfo && (
          <div className="absolute top-full left-0 mt-2 p-3 bg-white rounded-lg shadow-lg z-10 w-64">
            <p className="text-sm text-gray-600">
              Body Mass Index (BMI) is a measure of body fat based on height and weight. 
              A normal BMI range is 18.5 to 24.9.
            </p>
          </div>
        )}
      </div>
      
      <div className="grid grid-cols-4 gap-4">
        <div className="text-center">
          <div className="text-xl font-bold text-[#56C5B9]">
            {profile?.bmi || 'N/A'}
          </div>
          <div className="text-sm text-gray-500">BMI</div>
        </div>
        <div className="text-center">
          <div className="text-xl font-bold text-[#56C5B9]">
            {profile?.age || 'N/A'}
          </div>
          <div className="text-sm text-gray-500">Age</div>
        </div>
        <div className="text-center">
          <div className="text-xl font-bold text-[#56C5B9]">
            {profile?.height ? `${profile.height}cm` : 'N/A'}
          </div>
          <div className="text-sm text-gray-500">Height</div>
        </div>
        <div className="text-center">
          <div className="text-xl font-bold text-[#56C5B9]">
            {profile?.weight ? `${profile.weight}kg` : 'N/A'}
          </div>
          <div className="text-sm text-gray-500">Weight</div>
        </div>
      </div>
    </div>
  );
};

const MacrosSection = ({ profile }) => {
  const [showInfo, setShowInfo] = useState(false);
  const macros = profile?.macros;
  
  return (
    <div className="bg-white rounded-lg shadow-sm p-4 mb-6">
      <div className="flex items-center justify-between mb-3 relative">
        <h2 className="text-lg font-semibold">Daily Macros</h2>
        <button
          onClick={() => setShowInfo(!showInfo)}
          className="text-[#56C5B9] text-sm hover:underline"
        >
          About Macros
        </button>
        
        {showInfo && (
          <div className="absolute top-full right-0 mt-2 p-3 bg-white rounded-lg shadow-lg z-10 w-72">
            <h3 className="font-semibold mb-2">About Your Daily Macros</h3>
            <ul className="text-sm text-gray-600 space-y-2">
              <li><span className="font-medium">Calories:</span> Your total daily energy needs</li>
              <li><span className="font-medium">Protein:</span> Essential for muscle maintenance</li>
              <li><span className="font-medium">Carbs:</span> Primary energy source</li>
              <li><span className="font-medium">Fats:</span> Important for hormone function</li>
            </ul>
          </div>
        )}
      </div>
      
      <div className="grid grid-cols-4 gap-4">
        <div className="text-center">
          <div className="text-xl font-bold text-[#56C5B9]">
            {macros?.calories || 'N/A'}
          </div>
          <div className="text-sm text-gray-500">Calories</div>
        </div>
        <div className="text-center">
          <div className="text-xl font-bold text-[#56C5B9]">
            {macros?.protein ? `${macros.protein}g` : 'N/A'}
          </div>
          <div className="text-sm text-gray-500">Protein</div>
        </div>
        <div className="text-center">
          <div className="text-xl font-bold text-[#56C5B9]">
            {macros?.carbs ? `${macros.carbs}g` : 'N/A'}
          </div>
          <div className="text-sm text-gray-500">Carbs</div>
        </div>
        <div className="text-center">
          <div className="text-xl font-bold text-[#56C5B9]">
            {macros?.fats ? `${macros.fats}g` : 'N/A'}
          </div>
          <div className="text-sm text-gray-500">Fats</div>
        </div>
      </div>
    </div>
  );
};

export default function ProfilePage() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  const [profileData, setProfileData] = useState(null);
  const [preferences, setPreferences] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');


  const loadPreferences = async () => {
    try {
      const prefs = await getUserPreferences();
      setPreferences(prefs);
    } catch (error) {
      console.error('Error loading preferences:', error);
    }
  };

  useEffect(() => {
    const loadProfile = async () => {
      try {
        if (userId) {
          const response = await api.get(`/api/users/${userId}`);
          setProfileData(response.data);
        } else {
          setProfileData(user);
        }
      } catch (error) {
        console.error('Error loading profile:', error);
        setError('Failed to load profile');
      } finally {
        setLoading(false);
      }
    };

    loadProfile();
  }, [userId, user]);

  useEffect(() => {
    loadPreferences();
  }, []);

  const getCategoryCount = (status) => {
    return preferences.filter(pref => pref.status === status).length;
  };

  const preferenceCategories = [
    { title: 'Good for Me', status: 'ok', bgColor: 'bg-green-50/50', textColor: 'text-green-700' },
    { title: 'Cautious', status: 'sensitive', bgColor: 'bg-yellow-50/50', textColor: 'text-yellow-700' },
    { title: 'Avoid', status: 'avoid', bgColor: 'bg-red-50/50', textColor: 'text-red-700' }
  ];

  const isOwnProfile = !userId || userId === user._id;

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#56C5B9]" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="bg-red-100 text-red-700 p-4 rounded-lg">
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-50 min-h-screen pb-32">
      <div className="bg-[#56C5B9] pt-8 pb-16 px-4">
        <div className="max-w-xl mx-auto">
          <div className="flex items-center gap-4">
            <div className="w-16 h-16 bg-white rounded-full flex items-center justify-center">
              <span className="text-2xl text-[#56C5B9]">
                {profileData?.profile?.fullName?.[0]?.toUpperCase() || profileData?.username?.[0]?.toUpperCase()}
              </span>
            </div>
            <div className="text-white">
              <h1 className="text-xl font-semibold">{profileData?.profile?.fullName || profileData?.username}</h1>
              <p className="opacity-90">@{profileData?.username}</p>
              {profileData?.isBanned && (
                <span className="bg-red-100 text-red-600 text-sm px-2 py-1 rounded-full">
                  Account Suspended
                </span>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-xl mx-auto px-4 -mt-8">
        {isOwnProfile && (
          <div className="bg-white rounded-lg shadow flex divide-x mb-6">
            <button
              onClick={() => navigate('/edit-profile')}
              className="flex-1 p-4 flex items-center justify-center gap-2 hover:bg-gray-50"
            >
              <Edit size={18} />
              <span>Edit Profile</span>
            </button>
            <button
              onClick={() => navigate('/settings')}
              className="flex-1 p-4 flex items-center justify-center gap-2 hover:bg-gray-50"
            >
              <Settings size={18} />
              <span>Settings</span>
            </button>
          </div>
        )}

        <BMISection profile={profileData?.profile} />
        <MacrosSection profile={profileData?.profile} />

        <h2 className="text-xl font-bold mb-4">Food Preferences</h2>
        {preferenceCategories.map(category => (
          <button
            key={category.status}
            onClick={() => navigate(`/preferences/${category.status}`)}
            className={`w-full mb-3 p-4 ${category.bgColor} ${category.textColor} rounded-lg flex items-center justify-between hover:opacity-90`}
          >
            <span className="font-medium">
              {category.title} ({getCategoryCount(category.status)})
            </span>
            <ChevronRight size={20} />
          </button>
        ))}

        {isOwnProfile && user?.role === 'admin' && (
          <button
            onClick={() => navigate('/admin')}
            className="w-full mt-4 flex items-center justify-center gap-2 p-3 bg-[#56C5B9]/10 text-[#56C5B9] rounded-lg hover:bg-[#56C5B9]/20"
          >
            <ShieldCheck size={20} />
            <span className="font-medium">Admin Dashboard</span>
          </button>
        )}

        {isOwnProfile && (
          <button
            onClick={logout}
            className="w-full mt-8 flex items-center justify-center gap-2 p-3 text-red-600 bg-red-50/50 rounded-lg hover:bg-red-100/50"
          >
            <LogOut size={20} />
            <span className="font-medium">Logout</span>
          </button>
        )}
      </div>
    </div>
  );
}