import React, { useState } from 'react';
import { ArrowLeft, Bell, Scale, Languages, Salad } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { deleteUserAccount } from '../api/user';
import api from '../api/config';

function SettingsPage() {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [settings, setSettings] = useState({
    notifications: {
      comments: true,
      likes: true
    },
    units: 'metric',
    language: 'en',
    recipes: {
      filterByPreferences: true,
      hideAvoided: true
    }
  });

  const handleSettingToggle = async (category, setting) => {
    try {
      const newSettings = {
        ...settings,
        [category]: typeof setting === 'string' 
          ? setting 
          : {
              ...settings[category],
              ...setting
            }
      };
      
      await api.put('/api/users/settings', newSettings);
      setSettings(newSettings);
    } catch (err) {
      setError(err.message || 'Failed to update settings');
    }
  };

const handleDeleteAccount = async () => {
  if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
    try {
      setLoading(true);
      await deleteUserAccount(); // Use the API wrapper here
      alert('Your account has been deleted.');
      logout(); // Log the user out
    } catch (error) {
      console.error('Error deleting account:', error);
      alert('Failed to delete account. Please try again.');
    } finally {
      setLoading(false);
    }
  }
};

  const SettingToggle = ({ icon: Icon, title, description, value, onChange }) => (
    <div className="flex items-center justify-between py-4 border-b">
      <div className="flex items-start gap-3">
        <div className="p-2 bg-gray-100 rounded-lg">
          <Icon size={20} className="text-gray-600" />
        </div>
        <div>
          <h3 className="font-medium">{title}</h3>
          <p className="text-sm text-gray-500">{description}</p>
        </div>
      </div>
      <label className="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          className="sr-only peer"
          checked={value}
          onChange={onChange}
        />
        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-500"></div>
      </label>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50 pb-32">
      {/* Header */}
      <div className="bg-white border-b sticky top-0 z-10">
        <div className="p-4 flex items-center">
          <button onClick={() => navigate(-1)} className="mr-4">
            <ArrowLeft size={24} />
          </button>
          <h1 className="text-xl font-bold">Settings</h1>
        </div>
      </div>

      <div className="max-w-xl mx-auto p-4">
        {error && (
          <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-lg">
            {error}
          </div>
        )}

        {/* Notification Settings */}
        <div className="bg-white rounded-lg shadow mb-6">
          <div className="p-4">
            <h2 className="text-lg font-bold mb-2">Notifications</h2>
            <SettingToggle
              icon={Bell}
              title="Comment Notifications"
              description="Get notified when someone comments on your posts"
              value={settings.notifications.comments}
              onChange={() => handleSettingToggle('notifications', { 
                comments: !settings.notifications.comments 
              })}
            />
            <SettingToggle
              icon={Bell}
              title="Like Notifications"
              description="Get notified when someone likes your posts"
              value={settings.notifications.likes}
              onChange={() => handleSettingToggle('notifications', { 
                likes: !settings.notifications.likes 
              })}
            />
          </div>
        </div>

        {/* Units & Language */}
        <div className="bg-white rounded-lg shadow mb-6">
          <div className="p-4">
            <h2 className="text-lg font-bold mb-2">Display</h2>
            <div className="py-4 border-b">
              <div className="flex items-center justify-between">
                <div className="flex items-start gap-3">
                  <div className="p-2 bg-gray-100 rounded-lg">
                    <Scale size={20} className="text-gray-600" />
                  </div>
                  <div>
                    <h3 className="font-medium">Units</h3>
                    <p className="text-sm text-gray-500">Choose your preferred measurement system</p>
                  </div>
                </div>
                <select
                  value={settings.units}
                  onChange={(e) => handleSettingToggle('units', e.target.value)}
                  className="p-2 border rounded-lg"
                >
                  <option value="metric">Metric (kg, cm)</option>
                  <option value="imperial">Imperial (lb, in)</option>
                </select>
              </div>
            </div>
            <div className="py-4">
              <div className="flex items-center justify-between">
                <div className="flex items-start gap-3">
                  <div className="p-2 bg-gray-100 rounded-lg">
                    <Languages size={20} className="text-gray-600" />
                  </div>
                  <div>
                    <h3 className="font-medium">Language</h3>
                    <p className="text-sm text-gray-500">Choose your preferred language</p>
                  </div>
                </div>
                <select
                  value={settings.language}
                  onChange={(e) => handleSettingToggle('language', e.target.value)}
                  className="p-2 border rounded-lg"
                  disabled
                >
                  <option value="en">English</option>
                  <option value="disabled">More languages coming soon</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        {/* Recipe Preferences */}
        <div className="bg-white rounded-lg shadow">
          <div className="p-4">
            <h2 className="text-lg font-bold mb-2">Recipe Preferences</h2>
            <SettingToggle
              icon={Salad}
              title="Smart Recipe Filter"
              description="Show recipes based on your food preferences"
              value={settings.recipes.filterByPreferences}
              onChange={() => handleSettingToggle('recipes', { 
                filterByPreferences: !settings.recipes.filterByPreferences 
              })}
            />
            <SettingToggle
              icon={Salad}
              title="Hide Recipes with Avoided Foods"
              description="Don't show recipes containing foods you avoid"
              value={settings.recipes.hideAvoided}
              onChange={() => handleSettingToggle('recipes', { 
                hideAvoided: !settings.recipes.hideAvoided 
              })}
            />
          </div>
        </div>

        {/* Account Deletion */}
        <div className="mt-8">
          <button
            onClick={handleDeleteAccount}
            className="w-full text-red-600 bg-red-50 p-4 rounded-lg hover:bg-red-100 transition-colors"
            disabled={loading}
          >
            {loading ? 'Processing...' : 'Delete My Account'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default SettingsPage;