// src/api/food.js
import api from './config';

export const getFoodById = async (id) => {
  try {
    const response = await api.get(`/api/foods/${id}`);
    return response.data;
  } catch (error) {
    console.error('API Error:', error);
    throw error.response?.data || { message: 'Network error' };
  }
};

export const getAllFoods = async (page = 1, limit = 16, search = '') => {
  try {
    const response = await api.get('/api/foods', {
      params: {
        page,
        limit,
        search
      }
    });

    return {
      foods: response.data.foods || [],
      currentPage: response.data.currentPage || page,
      totalPages: response.data.totalPages || 1,
      total: response.data.total || 0,
      limit: response.data.limit || limit
    };
  } catch (error) {
    console.error('API Error:', error);
    throw error.response?.data || { message: 'Network error' };
  }
};

export const searchFoods = async (query) => {
  try {
    const response = await api.get('/api/foods/search', {
      params: { query }
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: 'Network error' };
  }
};