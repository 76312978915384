import { useState, useEffect } from 'react';
import axios from 'axios';

const CACHE_KEY = 'blogPosts';
const CACHE_DURATION = 24 * 60 * 60 * 1000;

export const useBlogPosts = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        // Check cache first
        const cached = localStorage.getItem(CACHE_KEY);
        if (cached) {
          const { data, timestamp } = JSON.parse(cached);
          if (Date.now() - timestamp < CACHE_DURATION) {
            setPosts(data);
            setLoading(false);
            return;
          }
        }

        const response = await axios.get('https://api.allorigins.win/raw?url=' + 
          encodeURIComponent('https://lipeplan.com/category/app/feed/'));
        
        const parser = new DOMParser();
        const xml = parser.parseFromString(response.data, 'text/xml');
        
    const items = Array.from(xml.querySelectorAll('item')).slice(0, 3);
        
    const parsedPosts = items.map(item => {
      // Extract only necessary fields
      const contentEncoded = item.getElementsByTagName('content:encoded')[0]?.textContent || '';
      const match = contentEncoded.match(/<media:content url="([^"]+)"/);
      const imageUrl = match ? match[1] : '/api/placeholder/400/300';

      return {
        title: item.querySelector('title')?.textContent || '',
        link: item.querySelector('link')?.textContent || '',
        description: item.querySelector('description')?.textContent || '',
        pubDate: new Date(item.querySelector('pubDate')?.textContent || ''),
        image: imageUrl
      };
    });

        setPosts(parsedPosts);
        
        localStorage.setItem(CACHE_KEY, JSON.stringify({
          data: parsedPosts,
          timestamp: Date.now()
        }));
      } catch (err) {
        console.error('Feed parsing error:', err);
        setError('Failed to load blog posts');
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  return { posts, loading, error };
};

export default useBlogPosts;