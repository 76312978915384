// src/pages/RecipeListPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { getAllRecipes } from '../api/recipe';
import OptimizedImage from '../components/OptimizedImage';

export default function RecipeListPage() {
  const navigate = useNavigate();
  const [recipes, setRecipes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    loadRecipes();
  }, [page]);

  const loadRecipes = async () => {
    try {
      setLoading(true);
      const data = await getAllRecipes(page);
      
      setRecipes(prev => [...prev, ...data.recipes]);
      setHasMore(page < data.totalPages);
    } catch (err) {
      setError('Failed to load recipes');
      console.error('Error loading recipes:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleLoadMore = () => {
    if (!loading && hasMore) {
      setPage(prev => prev + 1);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 pb-32">
      <div className="bg-[#56C5B9] pt-8 pb-16 px-4">
        <div className="max-w-xl mx-auto">
          <button
            onClick={() => navigate(-1)}
            className="mb-4 text-white"
          >
            <ArrowLeft size={24} />
          </button>
          <h1 className="text-2xl font-bold text-white">Recipes</h1>
        </div>
      </div>

      <div className="max-w-xl mx-auto px-4 -mt-8">
        {error && (
          <div className="bg-red-100 text-red-700 p-4 rounded-lg mb-4">
            {error}
          </div>
        )}

        <div className="space-y-4">
          {recipes.map(recipe => (
            <div
              key={recipe._id}
              onClick={() => navigate(`/recipes/${recipe._id}`)}
              className="bg-white rounded-lg shadow-sm overflow-hidden cursor-pointer"
            >
              {recipe.image && (
                <OptimizedImage
                  src={recipe.image}
                  alt={recipe.title}
                  className="w-full h-48 object-cover"
                />
              )}
              <div className="p-4">
                <h3 className="text-xl font-medium mb-2">{recipe.title}</h3>
                <p className="text-gray-600 mb-4">{recipe.description}</p>
                <div className="flex items-center text-sm text-gray-500">
                  <span>{recipe.preparationTime + recipe.cookingTime} mins</span>
                  <span className="mx-2">•</span>
                  <span>{recipe.servings} servings</span>
                </div>
              </div>
            </div>
          ))}

          {loading && (
            <div className="flex justify-center py-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#56C5B9]" />
            </div>
          )}

          {!loading && hasMore && (
            <button
              onClick={handleLoadMore}
              className="w-full py-3 bg-[#56C5B9] text-white rounded-lg"
            >
              Load More
            </button>
          )}

          {!loading && !hasMore && recipes.length === 0 && (
            <div className="text-center py-8 text-gray-500">
              No recipes found
            </div>
          )}
        </div>
      </div>
    </div>
  );
}