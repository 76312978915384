// src/api/user.js
import api from './config';

export const updateUserProfile = async (userData) => {
  try {
    const response = await api.put('/api/users/profile', userData);
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: 'Network error' };
  }
};

export const changePassword = async (passwords) => {
  try {
    const response = await api.put('/api/users/password', passwords);
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: 'Network error' };
  }
};

export const updateUserSettings = async (settings) => {
  try {
    const response = await api.put('/api/users/settings', settings);
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: 'Network error' };
  }
};

export const deleteUserAccount = async () => {
  try {
    const response = await api.delete('/api/users/delete');
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: 'Network error' };
  }
};