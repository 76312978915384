import React, { useState, useEffect } from 'react';
import { 
  PlusCircle, Search, X, Edit, Trash, 
  Save, ArrowLeft 
} from 'lucide-react';
import api from '../api/config';

const RecipeManagement = () => {
  const [foodSearchQuery, setFoodSearchQuery] = useState(''); 
  const [recipes, setRecipes] = useState([]);
  const [foods, setFoods] = useState([]);  // For ingredient selection
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    image: null,
    ingredients: [{ food: '', amount: '', unit: '' }],
    instructions: [''],
    preparationTime: '',
    cookingTime: '',
    servings: '',
    nutritionalInfo: {
      calories: '',
      protein: '',
      carbs: '',
      fats: '',
      fiber: ''
    },
    tags: []
  });

  useEffect(() => {
    loadRecipes();
  }, []);

useEffect(() => {
  if (foodSearchQuery.trim().length >= 2) {  // Only search when 2+ characters typed
    loadFoods(foodSearchQuery);
  }
}, [foodSearchQuery]);

const loadFoods = async (searchTerm = '') => {
  try {
    console.log('Searching foods with term:', searchTerm); // Debug log
    const response = await api.get('/api/foods', {
      params: {
        search: searchTerm,
        limit: 100
      }
    });
    console.log('Found foods:', response.data); // Debug log
    setFoods(response.data?.foods || []);
  } catch (error) {
    console.error('Error loading foods:', error);
    setFoods([]);
  }
};

  const loadRecipes = async () => {
    try {
      setLoading(true);
      const response = await api.get('/api/recipes');
      setRecipes(response.data?.recipes || []);
    } catch (error) {
      console.error('Error loading recipes:', error);
      setRecipes([]);
    } finally {
      setLoading(false);
    }
  };

const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      
      // Handle image
      if (formData.image) {
        formDataToSend.append('image', formData.image);
      }

      // Handle ingredients - append as stringified array of objects
      formDataToSend.append('ingredients', JSON.stringify(
        formData.ingredients.filter(ing => ing.food) // Only include ingredients with food selected
      ));

      // Handle tags - append as array, not array of arrays
      formDataToSend.append('tags', JSON.stringify(formData.tags));

      // Handle nutritional info
      formDataToSend.append('nutritionalInfo', JSON.stringify(formData.nutritionalInfo));

      // Handle instructions
      formDataToSend.append('instructions', JSON.stringify(formData.instructions));

      // Handle other basic fields
      formDataToSend.append('title', formData.title);
      formDataToSend.append('description', formData.description);
      formDataToSend.append('preparationTime', formData.preparationTime);
      formDataToSend.append('cookingTime', formData.cookingTime);
      formDataToSend.append('servings', formData.servings);

      console.log('Submitting recipe data:', {
        ingredients: JSON.parse(formDataToSend.get('ingredients')),
        tags: JSON.parse(formDataToSend.get('tags'))
      });

      if (selectedRecipe) {
        await api.put(`/api/recipes/${selectedRecipe._id}`, formDataToSend);
      } else {
        await api.post('/api/recipes', formDataToSend);
      }

      loadRecipes();
      setShowForm(false);
      setSelectedRecipe(null);
      resetForm();
    } catch (error) {
      console.error('Error saving recipe:', error);
      console.error('Error details:', error.response?.data);
    }
  };
  const handleDelete = async (recipeId) => {
    if (!window.confirm('Are you sure you want to delete this recipe?')) return;
    
    try {
      await api.delete(`/api/recipes/${recipeId}`);
      loadRecipes();
    } catch (error) {
      console.error('Error deleting recipe:', error);
    }
  };

  const handleEdit = (recipe) => {
    setSelectedRecipe(recipe);
    setFormData({
      ...recipe,
      image: null
    });
    setShowForm(true);
  };

  const resetForm = () => {
    setFormData({
      title: '',
      description: '',
      image: null,
      ingredients: [{ food: '', amount: '', unit: '' }],
      instructions: [''],
      preparationTime: '',
      cookingTime: '',
      servings: '',
      nutritionalInfo: {
        calories: '',
        protein: '',
        carbs: '',
        fats: '',
        fiber: ''
      },
      tags: []
    });
  };

  const filteredRecipes = recipes.filter(recipe => 
    recipe?.title?.toLowerCase().includes(foodSearchQuery.toLowerCase())
  );

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-bold">Recipe Management</h2>
        <button
          onClick={() => { setShowForm(true); resetForm(); }}
          className="flex items-center gap-2 bg-[#56C5B9] text-white px-4 py-2 rounded-lg"
        >
          <PlusCircle size={20} />
          <span>Add Recipe</span>
        </button>
      </div>

      {showForm ? (
        <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow p-6">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-lg font-semibold">
              {selectedRecipe ? 'Edit Recipe' : 'Add New Recipe'}
            </h3>
            <button
              type="button"
              onClick={() => { setShowForm(false); setSelectedRecipe(null); }}
              className="text-gray-500 hover:text-gray-700"
            >
              <X size={20} />
            </button>
          </div>

          {/* Basic Info */}
          <div className="space-y-4 mb-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Title</label>
              <input
                type="text"
                value={formData.title}
                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                className="w-full p-2 border rounded"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Description</label>
              <textarea
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                className="w-full p-2 border rounded h-24"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Image</label>
              <input
                type="file"
                onChange={(e) => setFormData({ ...formData, image: e.target.files[0] })}
                className="w-full p-2 border rounded"
                accept="image/*"
              />
            </div>
          </div>

          {/* Ingredients */}
<div className="mb-6">
  <label className="block text-sm font-medium text-gray-700 mb-2">Ingredients</label>
  
   {/* Search Foods */}
  <div className="relative mb-4">
<input
  type="text"
  placeholder="Search foods..."
  value={foodSearchQuery}
  onChange={(e) => {
    setFoodSearchQuery(e.target.value);
    if (e.target.value.trim().length < 2) {
      setFoods([]); // Clear foods when input is too short
    }
  }}
  className="w-full p-3 border rounded-lg"
/>
    {foodSearchQuery && foods.length > 0 && (
      <div className="absolute z-10 w-full mt-1 bg-white rounded-lg shadow-lg border max-h-60 overflow-y-auto">
        {foods.map(food => (
          <button
            key={food._id}
            onClick={() => {
              const exists = formData.ingredients.some(ing => ing.food === food._id);
              if (!exists) {
                setFormData({
                  ...formData,
                  ingredients: [
                    ...formData.ingredients,
                    { food: food._id, foodName: food.name, amount: '', unit: '' }
                  ]
                });
              }
              setFoodSearchQuery('');  // Clear food search
            }}
            className="w-full p-3 text-left hover:bg-gray-50 cursor-pointer"
          >
            {food.name}
          </button>
        ))}
      </div>
    )}
  </div>

  {/* Selected Ingredients */}
  <div className="space-y-4">
    {formData.ingredients.map((ingredient, index) => (
      <div key={index} className="p-4 bg-gray-50 rounded-lg">
        {/* Food Name and Remove Button */}
        <div className="flex justify-between items-center mb-3">
          <span className="font-medium">
            {foods.find(f => f._id === ingredient.food)?.name || ingredient.foodName}
          </span>
          <button
            type="button"
            onClick={() => {
              const newIngredients = formData.ingredients.filter((_, i) => i !== index);
              setFormData({ ...formData, ingredients: newIngredients });
            }}
            className="p-1 text-red-500 hover:bg-red-50 rounded"
          >
            <X size={20} />
          </button>
        </div>

        {/* Amount and Unit Inputs */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <input
              type="text"
              placeholder="Amount"
              value={ingredient.amount}
              onChange={(e) => {
                const newIngredients = [...formData.ingredients];
                newIngredients[index].amount = e.target.value;
                setFormData({ ...formData, ingredients: newIngredients });
              }}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Unit (g, ml, etc.)"
              value={ingredient.unit}
              onChange={(e) => {
                const newIngredients = [...formData.ingredients];
                newIngredients[index].unit = e.target.value;
                setFormData({ ...formData, ingredients: newIngredients });
              }}
              className="w-full p-2 border rounded"
              required
            />
          </div>
        </div>
      </div>
    ))}
  </div>
</div>

          {/* Instructions */}
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">Instructions</label>
            {formData.instructions.map((instruction, index) => (
              <div key={index} className="flex gap-4 mb-2">
                <span className="flex-none w-8 h-8 flex items-center justify-center bg-[#56C5B9] text-white rounded-full">
                  {index + 1}
                </span>
                <input
                  type="text"
                  value={instruction}
                  onChange={(e) => {
                    const newInstructions = [...formData.instructions];
                    newInstructions[index] = e.target.value;
                    setFormData({ ...formData, instructions: newInstructions });
                  }}
                  className="flex-1 p-2 border rounded"
                  required
                />
                <button
                  type="button"
                  onClick={() => {
                    const newInstructions = formData.instructions.filter((_, i) => i !== index);
                    setFormData({ ...formData, instructions: newInstructions });
                  }}
                  className="p-2 text-red-500 hover:bg-red-50 rounded"
                >
                  <X size={20} />
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => {
                setFormData({
                  ...formData,
                  instructions: [...formData.instructions, '']
                });
              }}
              className="text-[#56C5B9] hover:underline"
            >
              + Add Instruction
            </button>
          </div>

          {/* Other Details */}
          <div className="grid grid-cols-3 gap-4 mb-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Prep Time (mins)</label>
              <input
                type="number"
                value={formData.preparationTime}
                onChange={(e) => setFormData({ ...formData, preparationTime: e.target.value })}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Cook Time (mins)</label>
              <input
                type="number"
                value={formData.cookingTime}
                onChange={(e) => setFormData({ ...formData, cookingTime: e.target.value })}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Servings</label>
              <input
                type="number"
                value={formData.servings}
                onChange={(e) => setFormData({ ...formData, servings: e.target.value })}
                className="w-full p-2 border rounded"
                required
              />
            </div>
          </div>

          {/* Nutritional Info */}
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">Nutritional Information</label>
            <div className="grid grid-cols-5 gap-4">
              {Object.keys(formData.nutritionalInfo).map(key => (
                <div key={key}>
                  <label className="block text-xs text-gray-500 mb-1 capitalize">
                    {key} {key !== 'calories' && '(g)'}
                  </label>
                  <input
                    type="number"
                    value={formData.nutritionalInfo[key]}
                    onChange={(e) => setFormData({
                      ...formData,
                      nutritionalInfo: {
                        ...formData.nutritionalInfo,
                        [key]: e.target.value
                      }
                    })}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Tags */}
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">Tags</label>
            <div className="flex flex-wrap gap-4">
              {['anti-inflammatory', 'low-sodium', 'high-protein', 'gluten-free', 'dairy-free'].map(tag => (
                <label key={tag} className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    checked={formData.tags.includes(tag)}
                    onChange={(e) => {
                      const newTags = e.target.checked
                        ? [...formData.tags, tag]
                        : formData.tags.filter(t => t !== tag);
                      setFormData({ ...formData, tags: newTags });
                    }}
                    className="rounded"
                  />
                  <span className="text-sm capitalize">{tag}</span>
                </label>
              ))}
            </div>
          </div>

          <div className="flex justify-end gap-4">
            <button
              type="button"
              onClick={() => { setShowForm(false); setSelectedRecipe(null); }}
              className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-[#56C5B9] text-white rounded-lg"
            >
              {selectedRecipe ? 'Update Recipe' : 'Add Recipe'}
            </button>
          </div>
        </form>
      ) : (
        <>
          {/* Search */}
          <div className="mb-6">
            <div className="relative">
              <input
                type="text"
                placeholder="Search recipes..."
                value={foodSearchQuery}
                onChange={(e) => setFoodSearchQuery(e.target.value)}
                className="w-full p-3 pr-10 border rounded-lg"
              />
              <Search className="absolute right-3 top-3 text-gray-400" size={20} />
            </div>
          </div>

       {/* Recipes List */}
          <div className="space-y-4">
            {loading ? (
              <div className="flex justify-center py-12">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#56C5B9]" />
              </div>
            ) : filteredRecipes && filteredRecipes.length > 0 ? (
              filteredRecipes.map(recipe => (
                <div
                  key={recipe._id}
                  className="bg-white p-4 rounded-lg shadow flex items-start gap-4"
                >
                  {recipe.image && (
                    <img
                      src={recipe.image}
                      alt={recipe.title}
                      className="w-24 h-24 object-cover rounded-lg"
                    />
                  )}
                  
                  <div className="flex-1">
                    <h3 className="font-medium text-lg mb-1">{recipe.title}</h3>
                    <p className="text-sm text-gray-500 mb-2 line-clamp-2">
                      {recipe.description}
                    </p>
                    <div className="flex flex-wrap gap-2">
                      {recipe.tags?.map(tag => (
                        <span
                          key={tag}
                          className="px-2 py-1 bg-gray-100 text-gray-600 rounded text-xs"
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>

                  <div className="flex gap-2">
                    <button
                      onClick={() => handleEdit(recipe)}
                      className="p-2 text-blue-500 hover:bg-blue-50 rounded"
                    >
                      <Edit size={20} />
                    </button>
                    <button
                      onClick={() => handleDelete(recipe._id)}
                      className="p-2 text-red-500 hover:bg-red-50 rounded"
                    >
                      <Trash size={20} />
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center py-8 text-gray-500">
                {foodSearchQuery 
                  ? 'No recipes found matching your search'
                  : 'No recipes available. Add your first recipe!'}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default RecipeManagement;