import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Image, X, Camera, Tag, Check } from 'lucide-react';
import { createPost } from '../api/post';
import { getAllFoods } from '../api/food';
import api from '../api/config';

export default function CreatePostPage() {
  const navigate = useNavigate();
  const [content, setContent] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showFoodModal, setShowFoodModal] = useState(false);
  const [foods, setFoods] = useState([]);
  const [selectedFoods, setSelectedFoods] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState('');

  // Load foods when modal opens
  useEffect(() => {
    if (showFoodModal) {
      const loadFoods = async () => {
        try {
          const response = await api.get('/api/foods', {
            params: {
              page: 1,
              limit: 100,
              search: searchQuery
            }
          });
          
          if (response.data?.foods) {
            setFoods(response.data.foods);
          }
        } catch (error) {
          setError('Failed to load foods');
        }
      };

      // Only load foods if there's a search query
      if (searchQuery.trim().length >= 2) {
        loadFoods();
      } else {
        setFoods([]);
      }
    }
  }, [showFoodModal, searchQuery]);

  const filteredFoods = useMemo(() => {
    if (!searchQuery.trim()) return foods;
    
    const query = searchQuery.toLowerCase();
    return foods.filter(food => {
      if (!food) return false;
      const name = food.name?.toLowerCase() || '';
      const category = food.category?.toLowerCase() || '';
      return name.includes(query) || category.includes(query);
    });
  }, [foods, searchQuery]);

  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        alert('Image size should be less than 5MB');
        return;
      }
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => setPreviewUrl(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handleFoodSelect = (food) => {
    if (!food?._id) return;
    
    setSelectedFoods(prev => {
      const isSelected = prev.some(f => f._id === food._id);
      if (isSelected) {
        return prev.filter(f => f._id !== food._id);
      } else {
        return [...prev, food];
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!content.trim() && !selectedImage) {
      alert('Please add some content or an image');
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('content', content.trim());
      
      if (selectedImage) {
        formData.append('image', selectedImage);
      }

      selectedFoods.forEach(food => {
        if (food?._id) {
          formData.append('foods', food._id);
        }
      });

      const response = await createPost(formData);
      console.log('Post created:', response);
      navigate('/feed');
    } catch (error) {
      console.error('Error creating post:', error);
      alert(error.response?.data?.message || error.message || 'Failed to create post');
    } finally {
      setLoading(false);
    }
  };

  const handleModalOpen = () => {
    setError('');
    setShowFoodModal(true);
  };

  return (
    <div className="min-h-screen bg-gray-50 pb-32">
      <div className="bg-[#56C5B9] pt-8 pb-16 px-4">
        <div className="max-w-xl mx-auto">
          <h1 className="text-2xl font-bold text-white mb-2">Share Your Meal</h1>
          <p className="text-white/80">Share your meal with the community</p>
        </div>
      </div>

      <div className="max-w-xl mx-auto px-4 -mt-8">
        <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow-lg overflow-hidden">
          {/* Image Upload */}
          {previewUrl ? (
            <div className="relative">
              <img
                src={previewUrl}
                alt="Preview"
                className="w-full h-64 object-cover"
              />
              <button
                type="button"
                onClick={() => {
                  setSelectedImage(null);
                  setPreviewUrl(null);
                }}
                className="absolute top-4 right-4 p-2 bg-black/50 rounded-full text-white hover:bg-black/70"
              >
                <X size={20} />
              </button>
            </div>
          ) : (
            <div className="p-4 border-2 border-dashed border-gray-300 rounded-lg bg-gray-50">
              <label className="flex flex-col items-center justify-center h-48 cursor-pointer">
                <Camera size={48} className="text-gray-400 mb-2" />
                <span className="text-sm text-gray-500">Add photo of your meal</span>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageSelect}
                  className="hidden"
                />
              </label>
            </div>
          )}

          <div className="p-4">
            <textarea
              placeholder="Share your thoughts about this meal..."
              value={content}
              onChange={(e) => setContent(e.target.value)}
              className="w-full p-3 border rounded-lg resize-none h-32 focus:outline-none focus:ring-2 focus:ring-[#56C5B9]"
            />

            <div className="mt-4 mb-6">
              <button 
                type="button"
                onClick={handleModalOpen}
                className="flex items-center gap-2 text-[#56C5B9]"
              >
                <Tag size={20} />
                <span>Tag foods in this meal</span>
              </button>
              
              {selectedFoods.length > 0 && (
                <div className="mt-2 flex flex-wrap gap-2">
                  {selectedFoods.map(food => (
                    <span 
                      key={food._id}
                      className="px-2 py-1 bg-[#56C5B9]/10 text-[#56C5B9] rounded-full text-sm flex items-center gap-1"
                    >
                      {food.name}
                      <button
                        onClick={() => handleFoodSelect(food)}
                        className="ml-1 hover:text-red-500"
                        type="button"
                      >
                        <X size={14} />
                      </button>
                    </span>
                  ))}
                </div>
              )}
            </div>

            <button
              type="submit"
              disabled={loading || (!content.trim() && !selectedImage)}
              className="w-full py-3 bg-[#56C5B9] text-white rounded-lg font-medium disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? 'Posting...' : 'Share Post'}
            </button>
          </div>
        </form>
      </div>

      {/* Food Selection Modal */}
      {showFoodModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg w-full max-w-lg max-h-[80vh] overflow-hidden">
            <div className="p-4 border-b flex justify-between items-center">
              <h2 className="text-lg font-semibold">Tag Foods</h2>
              <button onClick={() => setShowFoodModal(false)} type="button">
                <X size={24} />
              </button>
            </div>

            <div className="p-4 border-b">
              <input
                type="text"
                placeholder="Search foods..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#56C5B9]"
              />
            </div>

            <div className="p-4 overflow-y-auto max-h-[60vh]">
              {error ? (
                <div className="text-center text-red-500 py-4">{error}</div>
              ) : filteredFoods.length > 0 ? (
                filteredFoods.map(food => (
                  <div
                    key={food._id}
                    onClick={() => handleFoodSelect(food)}
                    className={`p-3 flex items-center gap-3 rounded-lg cursor-pointer ${
                      selectedFoods.find(f => f._id === food._id)
                        ? 'bg-[#56C5B9]/10 border-[#56C5B9] border'
                        : 'hover:bg-gray-50'
                    }`}
                  >
                    <div className="flex-1">
                      <h3 className="font-medium">{food.name}</h3>
                      <p className="text-sm text-gray-500">{food.category}</p>
                    </div>
                    {selectedFoods.find(f => f._id === food._id) && (
                      <div className="text-[#56C5B9]">
                        <Check size={20} />
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <p className="text-center text-gray-500 py-4">
                  No foods found matching your search
                </p>
              )}
            </div>

            <div className="p-4 border-t">
              <button
                onClick={() => setShowFoodModal(false)}
                className="w-full py-2 bg-[#56C5B9] text-white rounded-lg font-medium"
                type="button"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}