// src/pages/LoginPage.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useGoogleLogin } from '@react-oauth/google';
import api from '../api/config';

export default function LoginPage() {
  const navigate = useNavigate();
  const { login } = useAuth(); 
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log('LoginPage mounted');
    try {
      // Initialize Apple Sign-in
      if (window.AppleID) {
        console.log('Configuring Apple Sign-in');
        window.AppleID.auth.init({
          clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
          scope: 'name email',
          redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URI,
          state: btoa(JSON.stringify({ redirect: window.location.origin }))
        });
      } else {
        console.warn('Apple Sign-in JS not loaded');
      }
    } catch (err) {
      console.error('Error initializing Apple Sign-in:', err);
      setError(err.message);
    }
  }, []);

  const handleAppleLogin = () => {
    try {
      const authUrl = `https://appleid.apple.com/auth/authorize?` + 
        `client_id=${process.env.REACT_APP_APPLE_CLIENT_ID}&` +
        `redirect_uri=${encodeURIComponent(process.env.REACT_APP_APPLE_REDIRECT_URI)}&` +
        `response_type=code%20id_token&` +
        `scope=name%20email&` +
        `response_mode=form_post`;
        
      console.log('Redirecting to Apple auth URL:', authUrl);
      window.location.href = authUrl;
    } catch (err) {
      console.error('Error during Apple login:', err);
      setError(err.message);
    }
  };

const handleGoogleLogin = useGoogleLogin({
  onSuccess: async (tokenResponse) => {
    try {
      console.log('Google token response received');
      const result = await api.post('/auth/google', {
        access_token: tokenResponse.access_token
      });
      
      console.log('Backend response:', result.data);
      console.log('isProfileComplete status:', result.data.user.isProfileComplete);

      if (result.data.token && result.data.user) {
        // First set the auth data
        await login(result.data);
        
        // Then handle navigation after auth is set
        if (!result.data.user.isProfileComplete) {
          console.log('User needs onboarding, redirecting...');
          setTimeout(() => {
            navigate('/onboarding', { replace: true });
          }, 0);
        } else {
          console.log('Profile complete, going to home');
          setTimeout(() => {
            navigate('/', { replace: true });
          }, 0);
        }
      } else {
        setError('Authentication failed. Please try again.');
      }
    } catch (error) {
      console.error('Google login error:', error);
      console.error('Error response:', error.response?.data);
      setError(error.response?.data?.message || 'Failed to sign in with Google. Please try again.');
    }
  },
  onError: (error) => {
    console.error('Google OAuth Error:', error);
    setError('Failed to sign in with Google. Please try again.');
  },
  scope: 'email profile'
});


  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="max-w-md w-full space-y-8 p-8 bg-white rounded-lg shadow">
        {error && (
          <div className="bg-red-100 text-red-700 p-4 rounded-lg mb-4">
            {error}
          </div>
        )}

        <div className="text-center">
          <img
            src="/logo.png"
            alt="LipePlan Logo"
            className="h-20 mx-auto mb-6"
          />
          <h1 className="text-3xl font-bold text-gray-900">Sign in to LipePlan</h1>
          <p className="mt-2 text-gray-600">Diet companion for Lipedema patients</p>
        </div>

        <button
          onClick={handleAppleLogin}
          className="w-full flex items-center justify-center gap-3 px-4 py-3 bg-black text-white rounded-lg hover:bg-gray-900 transition-colors"
        >
          <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
            <path d="M17.05 20.28c-.98.95-2.05.88-3.08.38-1.07-.52-2.04-.53-3.16 0-1.39.68-2.12.53-3-.38C3.33 15.85 3.96 8.67 8.5 8.14c1.23-.04 2.08.48 2.83.5.75.02 1.57-.6 2.91-.58 1.61.09 2.7.8 3.35 1.93-3.03 1.83-2.54 5.42.46 6.58-.55 1.35-1.3 2.7-2 3.71zM12.03 8c-.09-2.86 2.15-5.14 4.89-5-1.35 2.89.45 5.25-4.89 5z"/>
          </svg>
          <span className="font-medium">Continue with Apple</span>
        </button>

<button
  onClick={() => handleGoogleLogin()}
  className="w-full flex items-center justify-center gap-3 px-4 py-3 bg-white border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors mt-3"
>
  <svg className="w-5 h-5" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
    />
    <path
      fill="#34A853"
      d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
    />
    <path
      fill="#FBBC05"
      d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
    />
    <path
      fill="#EA4335"
      d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
    />
  </svg>
  <span className="font-medium">Continue with Google</span>
</button>

        <p className="text-sm text-center text-gray-500">
          By continuing, you agree to our{' '}
          <a href="/terms-of-service" className="text-[#56C5B9] hover:underline">
            Terms of Service
          </a>{' '}
          and{' '}
          <a href="/privacy-policy" className="text-[#56C5B9] hover:underline">
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
}