import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  RefreshCcw, 
  HelpCircle, 
  ActivitySquare, 
  Stethoscope,
  Salad,
  Dumbbell
} from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import Carousel from '../components/Carousel';
import { getRecommendations } from '../api/recipe';
import { useBlogPosts } from '../components/BlogFeed';

export default function HomePage() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [recipes, setRecipes] = useState([]);
  const [loadingRecipes, setLoadingRecipes] = useState(true);
  const { posts: blogPosts, loading: loadingBlogPosts } = useBlogPosts();

  const topics = [
    { 
      id: 1, 
      title: 'What is Lipedema?',
      description: 'Understanding the basics of lipedema, its causes, and risk factors',
      icon: HelpCircle,
      color: 'bg-pink-100',
      borderColor: 'border-pink-200',
      iconColor: 'text-pink-500'
    },
    { 
      id: 2, 
      title: 'Stages & Symptoms',
      description: 'Learn about different stages and how to identify symptoms',
      icon: ActivitySquare,
      color: 'bg-purple-100',
      borderColor: 'border-purple-200',
      iconColor: 'text-purple-500'
    },
    { 
      id: 3, 
      title: 'Treatment Options',
      description: 'Explore various treatment approaches and therapies',
      icon: Stethoscope,
      color: 'bg-blue-100',
      borderColor: 'border-blue-200',
      iconColor: 'text-blue-500'
    },
    { 
      id: 4, 
      title: 'Diet Guidelines',
      description: 'Recommended foods and nutrition tips for management',
      icon: Salad,
      color: 'bg-green-100',
      borderColor: 'border-green-200',
      iconColor: 'text-green-500'
    },
    { 
      id: 5, 
      title: 'Exercise Tips',
      description: 'Safe and effective exercises for lipedema patients',
      icon: Dumbbell,
      color: 'bg-yellow-100',
      borderColor: 'border-yellow-200',
      iconColor: 'text-yellow-500'
    }
  ];

  useEffect(() => {
    loadRecipes();
  }, []);

  const loadRecipes = async () => {
    try {
      setLoadingRecipes(true);
      const data = await getRecommendations();
      setRecipes(data);
    } catch (error) {
      console.error('Error loading recipes:', error);
    } finally {
      setLoadingRecipes(false);
    }
  };

  const renderBlogCard = (post) => (
    <a
      href={post.link}
      target="_blank"
      rel="noopener noreferrer"
      className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer h-full"
    >
      <div className="h-48 bg-gray-200">
        <img 
          src={post.image} 
          alt={post.title}
          className="w-full h-full object-cover"
          onError={(e) => {
            e.target.src = '/api/placeholder/400/300';
          }}
        />
      </div>
      <div className="p-6">
        <h3 className="text-xl font-medium mb-2">{post.title}</h3>
        <p className="text-gray-600 line-clamp-3">
          {post.description.replace(/<[^>]*>/g, '')}
        </p>
        <p className="text-xs text-gray-400 mt-2">
          {new Date(post.pubDate).toLocaleDateString()}
        </p>
      </div>
    </a>
  );

  const renderTopicCard = (topic) => {
    const Icon = topic.icon;
    return (
      <div
        onClick={() => navigate(`/education/${topic.id}`)}
        className={`${topic.color} border-2 ${topic.borderColor} p-6 rounded-xl cursor-pointer transition-all hover:scale-[1.02] h-full`}
      >
        <div className={`${topic.iconColor} mb-4`}>
          <Icon size={32} />
        </div>
        <h3 className="text-lg font-medium mb-2">{topic.title}</h3>
        <p className="text-gray-600">{topic.description}</p>
      </div>
    );
  };

const renderRecipeCard = (recipe) => (
  <div 
    onClick={() => navigate(`/recipes/${recipe._id}`)}
    className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer w-full h-full"
  >
    <div className="relative w-full h-48">
      <img 
        src={recipe.image} 
        alt={recipe.title}
        className="absolute inset-0 w-full h-full object-cover"
      />
    </div>
    <div className="p-4">
      <h3 className="text-xl font-medium mb-2">{recipe.title}</h3>
      <p className="text-gray-600 line-clamp-2 mb-4">{recipe.description}</p>
      <div className="flex items-center text-sm text-gray-500">
        <span>{recipe.preparationTime + recipe.cookingTime} mins</span>
        <span className="mx-2">•</span>
        <span>{recipe.servings} servings</span>
      </div>
    </div>
  </div>
);

  return (
    <div className="min-h-screen bg-gray-50 pb-32">
      {/* Welcome Section */}
      <div className="bg-[#56C5B9] pt-8 pb-8 px-4">
        <div className="max-w-xl mx-auto">
          <h1 className="text-2xl font-bold text-white mb-2">
            Welcome, {user?.username}!
          </h1>
          <p className="text-white/90">
            Track your food journey and connect with the community!
          </p>
        </div>
      </div>

      <div className="max-w-xl mx-auto mt-6">
        {/* Latest Blogs Section */}
        {loadingBlogPosts ? (
          <div className="flex justify-center py-12">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#56C5B9]" />
          </div>
        ) : (
          <Carousel
            title="Latest Blogs"
            items={blogPosts}
            renderItem={renderBlogCard}
            type="blog"
          />
        )}

        {/* Understanding Lipedema Section */}
        <Carousel
          title="Understanding Lipedema"
          items={topics}
          renderItem={renderTopicCard}
          type="education"
        />

        {/* Recipes Section */}
{loadingRecipes ? (
  <div className="flex justify-center py-12">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#56C5B9]" />
  </div>
) : recipes.length > 0 ? (
  <div className="mb-8">
    <Carousel
      title="Recipes For You"
      items={recipes}
      renderItem={renderRecipeCard}
    />
    
    <div className="px-4 mt-4">
      <button
        onClick={() => navigate('/recipes')}
        className="w-full py-3 text-[#56C5B9] border border-[#56C5B9] rounded-lg hover:bg-[#56C5B9] hover:text-white transition-colors"
      >
        Show More Recipes
      </button>
    </div>
  </div>
) : null}
      </div>
    </div>
  );
}