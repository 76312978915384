// src/api/recipe.js
import api from './config';

export const getRecommendations = async () => {
  try {
    const response = await api.get('/api/recipes/recommendations');
    return response.data;
  } catch (error) {
    console.error('Error fetching recommendations:', error);
    throw error.response?.data || { message: 'Error fetching recommendations' };
  }
};

export const getRecipe = async (id) => {
  try {
    const response = await api.get(`/api/recipes/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching recipe:', error);
    throw error.response?.data || { message: 'Error fetching recipe' };
  }
};

export const getAllRecipes = async (page = 1, limit = 10) => {
  try {
    const response = await api.get('/api/recipes', {
      params: {
        page,
        limit
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching all recipes:', error);
    throw error.response?.data || { message: 'Error fetching recipes' };
  }
};

// Admin functions
export const createRecipe = async (formData) => {
  try {
    const response = await api.post('/api/recipes', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating recipe:', error);
    throw error.response?.data || { message: 'Error creating recipe' };
  }
};

export const updateRecipe = async (recipeId, formData) => {
  try {
    const response = await api.put(`/api/recipes/${recipeId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error updating recipe:', error);
    throw error.response?.data || { message: 'Error updating recipe' };
  }
};

export const deleteRecipe = async (recipeId) => {
  try {
    const response = await api.delete(`/api/recipes/${recipeId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting recipe:', error);
    throw error.response?.data || { message: 'Error deleting recipe' };
  }
};