import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Info } from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import api from '../api/config';

export default function EditProfilePage() {
  const navigate = useNavigate();
  const { user, login } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showInfo, setShowInfo] = useState(false);
  
  const [formData, setFormData] = useState({
    fullName: user?.profile?.fullName || '',
    age: user?.profile?.age || '',
    height: user?.profile?.height || '',
    weight: user?.profile?.weight || '',
    activityLevel: user?.profile?.activityLevel || '',
    dietGoal: user?.profile?.dietGoal || ''
  });

  const calculateBMI = (height, weight) => {
    if (!height || !weight) return null;
    const heightInM = height / 100;
    return (weight / (heightInM * heightInM)).toFixed(1);
  };

  const calculateMacros = (profile) => {
    if (!profile || !profile.weight) return null;

    const leanFactor = profile.gender === 'male' ? 0.85 : 0.80;
    const leanMass = profile.weight * leanFactor;
    let bmr = 370 + (21.6 * leanMass);

    const activityMultipliers = {
      'sedentary': 1.2,
      'light': 1.375,
      'moderate': 1.55,
      'intense': 1.725
    };

    const tdee = bmr * (activityMultipliers[profile.activityLevel] || 1.2);

    let calorieTarget = tdee;
    if (profile.dietGoal === 'lose') calorieTarget *= 0.8;
    if (profile.dietGoal === 'gain') calorieTarget *= 1.1;

    return {
      calories: Math.round(calorieTarget),
      protein: Math.round((calorieTarget * 0.3) / 4),
      carbs: Math.round((calorieTarget * 0.4) / 4),
      fats: Math.round((calorieTarget * 0.3) / 9)
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const bmi = calculateBMI(formData.height, formData.weight);
      const macros = calculateMacros({
        ...formData,
        gender: user?.profile?.gender || 'female'
      });

      const userData = {
        profile: {
          ...formData,
          bmi,
          macros
        }
      };

      const response = await api.post('/api/users/profile', userData);
      
      if (response.data) {
        const updatedUser = {
          ...user,
          ...response.data
        };
        
        localStorage.setItem('user', JSON.stringify(updatedUser));
        await login({ token: localStorage.getItem('token'), user: updatedUser });
        navigate(-1);
      }
    } catch (err) {
      console.error('Error saving profile:', err);
      setError('Failed to update profile. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 pb-32">
      <div className="bg-white border-b sticky top-0 z-10">
        <div className="max-w-xl mx-auto p-4 flex items-center">
          <button onClick={() => navigate(-1)} className="mr-4">
            <ArrowLeft size={24} />
          </button>
          <h1 className="text-xl font-bold">Edit Profile</h1>
        </div>
      </div>

      <div className="max-w-xl mx-auto p-4">
        {error && (
          <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-lg">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Full Name
            </label>
            <input
              type="text"
              value={formData.fullName}
              onChange={(e) => setFormData({...formData, fullName: e.target.value})}
              className="w-full p-3 border rounded-lg"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Username
            </label>
            <input
              type="text"
              value={user?.username}
              className="w-full p-3 border rounded-lg bg-gray-50"
              disabled
            />
            <p className="mt-1 text-sm text-gray-500">
              Username cannot be changed
            </p>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Email
            </label>
            <input
              type="email"
              value={user?.email}
              className="w-full p-3 border rounded-lg bg-gray-50"
              disabled
            />
            <p className="mt-1 text-sm text-gray-500">
              Email cannot be changed
            </p>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Age
            </label>
            <input
              type="number"
              value={formData.age}
              onChange={(e) => setFormData({...formData, age: e.target.value})}
              className="w-full p-3 border rounded-lg"
              min="0"
              max="120"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Height (cm)
            </label>
            <input
              type="number"
              value={formData.height}
              onChange={(e) => setFormData({...formData, height: e.target.value})}
              className="w-full p-3 border rounded-lg"
              min="0"
              max="300"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Weight (kg)
            </label>
            <input
              type="number"
              value={formData.weight}
              onChange={(e) => setFormData({...formData, weight: e.target.value})}
              className="w-full p-3 border rounded-lg"
              min="0"
              max="500"
            />
          </div>

          <div className="relative">
            <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center gap-2">
              Activity Level
              <button
                type="button"
                onClick={() => setShowInfo(!showInfo)}
                className="text-gray-400 hover:text-gray-600"
              >
                <Info size={16} />
              </button>
            </label>
            {showInfo && (
              <div className="absolute z-10 bg-white p-3 rounded-lg shadow-lg border text-sm">
                We use this to calculate your Basal Metabolic Rate (BMR)
              </div>
            )}
            <select
              value={formData.activityLevel}
              onChange={(e) => setFormData({...formData, activityLevel: e.target.value})}
              className="w-full p-3 border rounded-lg"
            >
              <option value="">Select activity level</option>
              <option value="sedentary">Sedentary: little to no exercise</option>
              <option value="light">Light: exercise 1-3 times per week</option>
              <option value="moderate">Moderate: exercise 4-5 times per week</option>
              <option value="intense">Intense: daily exercise or physical job</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Your Goal
            </label>
            <select
              value={formData.dietGoal}
              onChange={(e) => setFormData({...formData, dietGoal: e.target.value})}
              className="w-full p-3 border rounded-lg"
            >
              <option value="">Select goal</option>
              <option value="lose">Lose weight</option>
              <option value="maintain">Maintain</option>
              <option value="gain">Gain muscle</option>
            </select>
          </div>

          <button
            type="submit"
            disabled={loading}
            className="w-full py-3 bg-[#56C5B9] text-white rounded-lg font-medium disabled:opacity-50"
          >
            {loading ? 'Saving...' : 'Save Changes'}
          </button>
        </form>
      </div>
    </div>
  );
}