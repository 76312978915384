import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search as SearchIcon, ChevronLeft, ChevronRight } from 'lucide-react';
import { getAllFoods } from '../api/food';
import { getUserPreferences, updatePreference } from '../api/preferences';

// Popular items that should appear by default
const popularItems = [
  'Banana', 'Egg', 'Apple', 'Rice', 'Chicken Breast', 
  'Salmon', 'Almond', 'Tomato', 'Avocado', 'Milk'
];

export default function SearchPage() {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [foods, setFoods] = useState([]);
  const [loading, setLoading] = useState(true);
  const [preferences, setPreferences] = useState({});
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    total: 0
  });

const loadData = async (page = 1) => {
  try {
    setLoading(true);
    const [foodData, prefsData] = await Promise.all([
      getAllFoods(page, 16, searchQuery),
      getUserPreferences()
    ]);

    let foodsToDisplay;
    if (!searchQuery && page === 1) {
      // Show popular items by default
      foodsToDisplay = foodData.foods.filter(food => 
        food && popularItems.some(item => 
          food.name?.toLowerCase().includes(item.toLowerCase())
        )
      );
    } else {
      // Filter out any null/undefined foods
      foodsToDisplay = foodData.foods.filter(food => food && food._id);
    }

    setFoods(foodsToDisplay);
    setPagination({
      currentPage: foodData.currentPage,
      totalPages: foodData.totalPages,
      total: foodData.total
    });
    
    const prefsMap = {};
    prefsData.forEach(pref => {
      if (pref.food && pref.food._id) {  // Add null check here too
        prefsMap[pref.food._id] = pref.status;
      }
    });
    setPreferences(prefsMap);
  } catch (error) {
    console.error(error);
  } finally {
    setLoading(false);
  }
};

  useEffect(() => {
    loadData(1);
  }, [searchQuery]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pagination.totalPages) {
      loadData(newPage);
      window.scrollTo(0, 0);
    }
  };

  const handlePreferenceUpdate = async (foodId, status) => {
    try {
      await updatePreference(foodId, status);
      setPreferences(prev => ({
        ...prev,
        [foodId]: status
      }));
      loadData(pagination.currentPage);
    } catch (error) {
      console.error('Error updating preference:', error);
    }
  };

  const FoodCard = ({ food }) => {
    const handleCardClick = (e) => {
      if (!e.target.closest('button')) {
        navigate(`/food/${food._id}`);
      }
    };

    return (
      <div 
        onClick={handleCardClick}
        className="bg-white p-4 rounded-lg shadow cursor-pointer hover:shadow-md transition-shadow"
      >
        <h3 className="font-semibold text-lg mb-2">{food.name}</h3>
        
        {food.antiInflammatory && (
          <span className="bg-green-100 text-green-800 text-xs px-2 py-1 rounded-full">
            Anti-inflammatory
          </span>
        )}
        
        {food.stats && (
          <div className="grid grid-cols-3 gap-2 p-3 bg-gray-50 rounded-lg mb-4 mt-3">
            <div className="text-center">
              <p className="text-sm font-bold text-green-600">
                {Math.round((food.stats.ok / (food.stats.total || 1)) * 100)}%
              </p>
              <p className="text-xs text-gray-500">Good for Me</p>
            </div>
            <div className="text-center">
              <p className="text-sm font-bold text-yellow-600">
                {Math.round((food.stats.sensitive / (food.stats.total || 1)) * 100)}%
              </p>
              <p className="text-xs text-gray-500">Cautious</p>
            </div>
            <div className="text-center">
              <p className="text-sm font-bold text-red-600">
                {Math.round((food.stats.avoid / (food.stats.total || 1)) * 100)}%
              </p>
              <p className="text-xs text-gray-500">Avoid</p>
            </div>
          </div>
        )}

        <div className="flex gap-2">
          <button
            onClick={() => handlePreferenceUpdate(food._id, 'ok')}
            className={`flex-1 py-2 rounded-lg text-sm ${
              preferences[food._id] === 'ok'
                ? 'bg-green-500 text-white'
                : 'bg-gray-100 hover:bg-gray-200'
            }`}
          >
            Good for Me
          </button>
          <button
            onClick={() => handlePreferenceUpdate(food._id, 'sensitive')}
            className={`flex-1 py-2 rounded-lg text-sm ${
              preferences[food._id] === 'sensitive'
                ? 'bg-yellow-500 text-white'
                : 'bg-gray-100 hover:bg-gray-200'
            }`}
          >
            Cautious
          </button>
          <button
            onClick={() => handlePreferenceUpdate(food._id, 'avoid')}
            className={`flex-1 py-2 rounded-lg text-sm ${
              preferences[food._id] === 'avoid'
                ? 'bg-red-500 text-white'
                : 'bg-gray-100 hover:bg-gray-200'
            }`}
          >
            Avoid
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50 pb-32">
      <div className="bg-[#56C5B9] pt-8 pb-16 px-4">
        <div className="max-w-xl mx-auto">
          <h1 className="text-2xl font-bold text-white mb-4">Find Foods</h1>
          <div className="relative">
            <input
              type="text"
              placeholder="Search foods..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full p-4 pr-12 rounded-lg shadow-lg"
            />
            <SearchIcon className="absolute right-4 top-4 text-gray-400" size={20} />
          </div>
        </div>
      </div>

      <div className="max-w-xl mx-auto px-4 -mt-8">
        {loading ? (
          <div className="flex justify-center py-12">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#56C5B9]" />
          </div>
        ) : (
          <>
            <div className="grid grid-cols-1 gap-4">
{foods.filter(food => food !== null).map(food => (
  <FoodCard key={food._id} food={food} />
))}
            </div>

            {foods.length === 0 && (
              <div className="text-center py-8 text-gray-500">
                No foods found matching your search
              </div>
            )}

            {foods.length > 0 && !searchQuery && (
              <div className="mt-8 flex items-center justify-center gap-4">
                <button
                  onClick={() => handlePageChange(pagination.currentPage - 1)}
                  disabled={pagination.currentPage === 1}
                  className="p-2 border rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <ChevronLeft size={20} />
                </button>
                
                <span className="text-gray-600">
                  Page {pagination.currentPage} of {pagination.totalPages}
                </span>
                
                <button
                  onClick={() => handlePageChange(pagination.currentPage + 1)}
                  disabled={pagination.currentPage === pagination.totalPages}
                  className="p-2 border rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <ChevronRight size={20} />
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}